


































import { Component } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import SupportModule from '@/store/modules/support'

@Component({
  components: {
    TextInput,
  },
})
export default class FAQList extends NotifyMixin {
  private get search() {
    return SupportModule.faqSearch
  }

  @Bind()
  @Debounce(500)
  private set search(value: string) {
    if (value !== this.search) {
      SupportModule.setFAQSearch(value)
      this.fetchFAQList(value)
    }
  }

  private get faqList() {
    return SupportModule.faqList
  }

  private get openedFAQList() {
    return SupportModule.openedFAQList
  }

  private isLoaded = false
  private faqListState: number[] = []

  private mounted() {
    if (!this.faqList.length)
      this.fetchFAQList()
    else
      this.isLoaded = true
  }

  private fetchFAQList(query?: string) {
    this.isLoaded = false
    SupportModule.fetchFAQList({ query })
      .then(() => {
        this.faqListState = []
        this.faqListState = [...this.faqListState]
      })
      .catch(this.notifyError)
      .finally(() => {
        this.isLoaded = true
      })
  }

  private loadAnswer(id: number, index: number) {
    if (this.faqListState.includes(index)) {
      this.faqListState = this.faqListState.filter(item => item !== index)
      return
    }

    if (this.openedFAQList[id]) {
      this.faqListState.push(index)
      return
    }

    SupportModule.fetchFAQ(id)
      .then(() => {
        this.faqListState.push(index)
      })
      .catch(this.notifyError)
  }
}
